import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Header } from 'components';
import { Layout, Container } from 'layouts';
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
//import { FaLinkedin } from "react-icons/fa";
//import { FaTwitter } from "react-icons/fa";


const About = center => (
  <Layout>
    <Helmet title={'À propos'} />
    <Header title="À propos"></Header>
    <Container center={center}>
      <p>
        Je m'appelle Akram ZAIRIG, je suis développeur Drupal certifié par 
        <a href="https://certification.acquia.com/user/6683" target="_blank"> <strong>Acquia</strong> </a>.
         </p>
      <p>
        J'ai créé ce blog en 2019 pour partager ma modeste expérience en Drupal.
        Si vous êtes aussi passionné par Drupal, je vous invite à lire <a href="/articles" target="_blank"> <strong>les articles dans mon blog</strong> </a> et me suivre dans les réseaux sociaux.
      </p>
      <a href="https://www.linkedin.com/in/akramzairig/" target="_blank" style={{'color': '#0e76a8','font-size': '35px','margin': '0 5px'}}><FaLinkedin /></a>
      <a href="https://github.com/akramzairig" target="_blank" style={{'color': '#211F1F','font-size': '35px','margin': '0 5px'}}><FaGithub /></a>
      <a href="https://twitter.com/akram_zairig" target="_blank" style={{'color': '#00acee', 'font-size': '35px','margin': '0 5px'}}><FaTwitter /></a>
    </Container>
  </Layout>
);

export default About;

About.propTypes = {
  center: PropTypes.object,
};
